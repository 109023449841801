import axios from 'axios';

// Set up a base instance of axios for easier use and configuration
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Interceptor Error:', error);
    return Promise.reject(error); // Ensure error propagates to `catch`
  }
);

// Function to get list of assistants.
export const getAssistants = async () => {
  try {
    const response = await apiClient.get('/assistant/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching assistants:', error);
    throw error;
  }
};

// Function to load a conversation.
export const startConversation = async () => {
  try {
    const response = await apiClient.get('/conversation/start');
    return response.data;
  } catch (error) {
    console.error('Error starting conversations:', error);
    throw error;
  }
};

// Function to load a conversation.
export const getConversation = async (conversationId) => {
  try {
    const response = await apiClient.get('/conversation/get/' + conversationId);
    return response.data;
  } catch (error) {
    console.error('Error loading conversations:', error);
    throw error;
  }
};

// Function to post a new message to the conversation
export const addMessage = async (conversation_id, thread_id, message) => {
  try {
    const response = await apiClient.post('/conversation/message', {
      conversation_id: conversation_id,
      thread_id: thread_id,
      user_message: message,
    });
    return response.data;
  } catch (error) {
    console.error('Error posting message:', error);
    throw error;
  }
};
