import React from 'react';
import { Card } from 'primereact/card';

export default function ConversationMessage({ response }) {
  return (
    <Card title="">
      <p className="m-0">
        {response}
      </p>
    </Card>
  )
}
